/* http://meyerweb.com/eric/tools/css/reset/
   v2.0b1 | 201101
   NOTE: WORK IN PROGRESS
   USE WITH CAUTION AND TEST WITH ABANDON */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* remember to define visible focus styles!
   :focus {
       outline: ?????;
   } */

/* remember to highlight inserts somehow! */

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ### RESET END ***/

@font-face {
  font-family: "Avenir Next LT W04 Condensed";
  src: url("./assets/font/AvenirNextCondensed.eot?#iefix");
  src: url("./assets/font/AvenirNextCondensed.eot?#iefix") format("eot"),
    url("./assets/font/AvenirNextCondensed.woff2") format("woff2"),
    url("./assets/font/AvenirNextCondensed.woff") format("woff"),
    url("./assets/font/AvenirNextCondensed.ttf") format("truetype");
}

@font-face {
  font-family: "DINCondensed-Regular";
  src: url("./assets/font/DINCondensed.eot");
  src: url("./assets/font/DINCondensed.eot?#iefix") format("embedded-opentype"),
    url("./assets/font/DINCondensed.woff2") format("woff2"), url("./assets/font/DINCondensed.woff") format("woff"),
    url("./assets/font/DINCondensed.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

::-webkit-input-placeholder {
  /* Chrome */
  color: #e95849;
}

.inputBlack:-ms-input-placeholder {
  /* IE 10+ */
  color: #e95849;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #e95849;
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #e95849;
  opacity: 1;
}

body {
  font-family: "Avenir Next LT W04 Condensed";
  margin: 0;
  padding: 0;
  background-color: #1b2e3e;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.logo {
  width: 38px;
  padding: 0px 0 0 20px;
  cursor: pointer;
}

.navigation {
  order: 0;
  z-index: 150;
  position: absolute;
  width: 100%;
  background-color: #1b2e3e;
  height: 43px;
  box-shadow: 0px 1px 5px #2a2a2a;
  min-width: 850px;
}

.navSection {
  float: left;
  margin: 6px 50px 0px 0px;
  height: 37px;
  width: 110px;
}

.navSection.shopping {
  float: right;
  margin-right: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 80px;
}

.navSection.shopping > a {
  color: #326087;
}

.navSection.shopping > a:hover {
  color: #5393ca;
}

.mr-10 {
  margin-right: 10px;
}

.northeast {
  transform: rotate(-45deg);
}

.navSectionLogo {
  width: 125px;
}

.navSectionProg {
  width: 140px;
}

.navSection > .title {
  margin: 0;
  padding: 8px 0px 0px 0px;
  color: #5393ca;
  font-size: 12px;
  line-height: 16px;
  /*margin-left: 3px;*/
  border-bottom: 1px solid #5393ca;
}

.nav,
.navSmall {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav > li {
  border-bottom: 4px solid #326087;
  text-align: center;
  width: 100%;
  padding-top: 3px;
  float: left;
  font-size: 20px !important;
  line-height: 30px;
  color: #e7e7e4;
  text-decoration: none;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
}

.navSmall > li {
  padding-top: 18px;
  margin-right: 20px;
  float: left;
  font-size: 17px !important;
  color: #e7e7e4;
  text-decoration: none;
  cursor: pointer;
}

.nav > li:hover:hover {
  background: #1f3d56;
}

.nav > .active {
  /* color: #00C90A!important; */
  background: #1f3d56;
}

.navSmall > li:hover {
  color: #6bd425;
}

.navSmall > .active {
  color: #00c90a;
}

.navSmall > .title {
  cursor: default;
  color: #5393ca;
  width: 95px;
}

.navSmall > .title:hover {
  color: #5393ca;
}

.navSmall > .error {
  color: #e95849 !important;
}

.router-link-active {
  color: #00c90a !important;
}

.programEditWrapper {
  width: 100%;
  height: 100%;
}

#blocklyWrapper {
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.program {
  color: white;
  clear: both;
  width: 100%;
}

.programAttributes {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.programAttributes > li {
  cursor: pointer;
}

.program {
  height: 25px;
  border-top: 1px solid #393939;
  line-height: 25px;
}

.program:last-of-type {
  border-bottom: 1px solid #393939;
}

.serverResponseWrapper > .compiling {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.program > .selected > li {
  background-color: #333333;
}

.program > .error > li {
  color: #e95849 !important;
}

.programAttributes > .date {
  width: 80px;
}

.programAttributes > .time {
  width: 55px;
}

.programAttributes > .name {
  flex: 1;
}

.programStatusCircleWrapper {
  position: relative;
  top: 0px;
}

.programStatusCircles {
  position: absolute;
  right: 7px;
  top: 0px;
  width: 100px;
  padding-top: 3px;
}

.inputWrapper > .programStatusCircleWrapper > .programStatusCircles {
  top: 8px;
}

.statusCircle {
  width: 18px;
}

.programStatusCircles > .statusCircle {
  float: right;
}

.connectServerSection > .programStatusCircleWrapper > .programStatusCircles {
  right: 27px;
  padding-top: 1px;
  width: 26px;
}

.programStatusCircles > .statusCircleArmed {
  margin-left: 8px;
}

#programLoadStartupDialog {
  position: fixed;
  z-index: 190;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1b2e3e;
  color: #e7e7e4;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navProgramLoadStartupDialog > li {
  float: left;
  text-decoration: none;
  cursor: pointer;
}

#navProgramLoadStartupDialog > li:first-child {
  margin-right: 100px;
}

#navProgramLoadStartupDialog > li:hover {
  color: #6bd425;
}

#blocklyStartupDialog {
  position: absolute;
  z-index: 110;
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navStartupDialog {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modalDialog {
  position: absolute;
  top: 70px;
  height: 100%;
  box-shadow: 0px 0px 5px #2a2a2a !important;
  background-color: #171717;
  z-index: 160;
}

.modalOutline {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
}

/* ### EDITOR MODAL CODE START */

#editorModalCodeWrapper {
  background-color: #171717;
  /*color: white;*/
  display: flex;
  flex-direction: column;
  min-width: 405px;
}

.modalCodeNavigation {
  position: relative;
  height: 55px;
  padding-left: 20px;
}

.modalCodeNavigation > .secondaryToolbar {
  float: right;
  margin-right: 20px;
}

#codeArea,
#xmlArea {
  width: 100%;
}

.modalCodeWrapper {
  flex: 1;
  padding: 0 20px 20px 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modalCodeTextarea {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  resize: none;
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
  background-color: #f2f2f2;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.modalCode > p {
  margin: 0;
  padding: 20px;
  background-color: #f2f2f2;
  color: black;
}

.modalCodeFooter {
  position: relative;
  height: 20px;
}

/* ### EDITOR MODAL CODE END */

/**### EDITOR MODAL SIMULATOR START **/

#editorModalSimulatorInputWrapper,
#editorModalSimulatorOutputWrapper,
#editorModalDivSpeedWrapper {
  background-color: #171717;
  font-size: 15px;
}

#editorModalSimulatorInputWrapper {
  top: 70px;
  left: 125px;
  height: 160px;
  min-width: 86vw !important;
  display: flex;
  flex-direction: row;
  padding-top: 25px;
}

#simulatorTitleSection {
  width: 130px;
}

#simulatorInputSection {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.simulatorInputTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 120px;
  min-width: 120px;
  height: 104px;
  color: #ce6400;
  margin-right: 15px;
  text-align: right;
}

.simulatorInputTitleWrapper > .top {
  position: absolute;
  top: 4px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}

.simulatorInputTitleWrapper > .bottom {
  position: absolute;
  bottom: 42px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
}

.simulatorInputGroupWrapper {
  width: 150px;
  min-width: 150px;
  height: 145px;
  margin-right: 25px;
  display: block;
}

.simulatorInputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.simulatorInputGroupSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 104px;
  padding-bottom: 16px;
  position: relative;
}

.simulatorInputToggle {
  height: 48px;
}

.simulatorInputButton {
  height: 26px;
}

.simulatorInputGroupTitle {
  width: 100%;
  height: 22px;
  border-top: 1px solid #5393ca;
  color: #5393ca;
  font-size: 20px;
  padding-top: 3px;
}

.simulatorInputGroupTitle > p {
  margin: 0;
  padding: 0;
  text-align: right;
}

.simulatorInputGroup > .section {
  width: 50%;
  height: 100%;
  float: left;
}

.simulatorInputGroup > .section > img {
  display: block;
}

.simulatorInputGroup > .sectionLeft {
  text-align: left;
}

.simulatorInputGroup > .sectionRight {
  text-align: right;
}

.simulatorInputGroup > .section > .simulatorInputButton {
  margin-top: 30px;
  -webkit-touch-callout: none;
}

.simulatorInputGroup > .section > .simulatorInput {
  margin: 80px 4px 0px 0px;
}

.simulatorInput {
  background-color: black;
  border: 1px solid #e7e7e4;
  border-radius: 7px;
  font-family: "Avenir Next LT W04 Condensed" !important;
  font-size: 17px !important;
  line-height: 27px;
  height: 27px;
  width: 45px;
  background-color: #171717;
  text-align: center;
  color: #ce6400;
  margin: 0 4px 0 4px;
}

#editorModalSimulatorOutputWrapper {
  top: 90% !important;
  left: 125px;
  height: 47px;
  color: #ce6400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 86vw !important;
}

#simulatorMotorOutputWrapper {
  padding-left: 24px;
  width: 250px;
  min-width: 250px;
}

#simulatorLedOutputWrapper {
  width: 480px;
  min-width: 480px;
  padding-right: 6px;
}

.simulatorOutputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.motorGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 120px;
  min-width: 120px;
}

.ledGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 90px;
  min-width: 90px;
}

#editorModalDivSpeedWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 290px;
  right: 3vw;
  width: 240px;
  height: 47px;
  color: #ce6400;
  padding: 0 6px 0 12px;
}

#simulationSpeedRange {
  color: white;
}

.simulatorInputAnalogRange {
  width: 104px !important;
  transform: rotate(-90deg);
  position: absolute;
  top: 42%;
  left: -50px;
}

input[class="simulatorInputAnalogRange"]::-moz-range-track {
  width: 104px !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .simulatorInputAnalogRange {
    top: 32%;
  }
  .inputBlack {
    line-height: inherit !important;
  }
  .inputWrapper > .programStatusCircleWrapper > .programStatusCircles {
    top: 2px;
  }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */

@supports (-ms-accelerator: true) {
  .simulatorInputAnalogRange {
    top: 26%;
    width: 103px !important;
  }
  input[type="range"]::-ms-thumb {
    margin-top: -1px !important;
  }
}

/**### EDITOR MODAL SIMULATOR END **/

/* ### EDITOR MODAL CONNECT START */

#editorModalConnectWrapper {
  background-color: #171717;
  color: white;
  display: flex;
  flex-direction: column;
  min-width: 800px;
}

#editorModalConnectWrapper > .connectLocalSection {
  height: 100px;
  padding: 20px;
}

#editorModalConnectWrapper > .connectServerSection {
  padding-left: 20px;
}

#editorModalConnectWrapper > .connectLocalSection > .localInputWrapper {
  border-top: 1px solid #e7e7e4;
  padding-top: 12px;
  clear: both;
}

.serverResponseWrapper {
  border-top: 1px solid #e7e7e4;
  color: white;
  position: relative;
  overflow: auto;
  margin: 0px 10px 12px 20px;
  padding-top: 25px;
  flex: 1;
  -webkit-overflow-scrolling: touch;
}

.serverResponseWrapper ::-webkit-scrollbar {
  width: 5px;
  height: 8px !important;
  -webkit-appearance: none;
}

.serverResponseWrapper::-webkit-scrollbar-track {
  background: #666666;
}

.serverResponseWrapper::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}

.serverResponseWrapper::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.localInputWrapper > .inputWrapper > input {
  width: 100%;
  float: left;
  padding: 3px 0px 3px 10px;
  margin-right: 12px;
}

.inputBlack {
  background-color: black;
  border: 1px solid #e7e7e4;
  border-radius: 7px;
  font-family: "Avenir Next LT W04 Condensed" !important;
  font-size: 17px !important;
  line-height: 28px;
  color: white;
  background-color: #171717;
}

/**### EDITOR MODAL CONNECT END **/

/* ### EDITOR MODAL HELP START */

#editorModalHelpWrapper {
  display: flex;
  color: white;
  min-width: 616px;
}

#modalHelpNavTriangleWrapper {
  position: absolute;
  top: -25px;
  left: 579px;
}

#modalHelpWrapper {
  position: relative;
  margin: 20px 20px 20px 20px;
  flex: 1;
  overflow: hidden;
  /*background-color: #F2F2F2;*/
  background-color: white;
  color: black;
}

#modalHelp {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-size: 17px;
}

#modalHelp p {
  padding: 20px;
  font-size: 20px;
}

#modalHelp a {
  color: #0294cf;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

/**### EDITOR MODAL HELP END **/

/**### EDITOR MODAL END **/

.secondaryToolbar > .toolbarButton {
  height: 27px;
  margin-left: 18px;
  padding-top: 12px;
}

.secondaryToolbar > .inactive {
  opacity: 0.3;
}

.connectLocalSection > .navSmall > li,
.connectServerSection > .navSmall > li {
  padding: 0px 0px 6px 10px;
}

.navSmall > .inactive,
.navSmall > .inactive:hover {
  color: #666666;
  cursor: default;
}

.closeButton {
  width: 38px;
}

.closeButtonWrapper {
  position: absolute;
  top: -19px;
  right: -19px;
}

#modalCodeNavTriangleWrapper {
  position: absolute;
  top: -25px;
  left: 210px;
}

#modalConnectNavTriangleWrapper {
  position: absolute;
  top: -25px;
  left: 530px;
}

#modalSettingsNavTriangleWrapper {
  position: absolute;
  top: -25px;
  right: 10px;
}

#modalSimulatorNavTriangleWrapper {
  position: absolute;
  top: -25px;
  left: 366px;
}

.navTriangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 25px 16px;
  border-color: transparent transparent #171717 transparent;
}

#logoStartupScreen {
  opacity: 100;
  -webkit-transition: opacity 1.5s linear;
  transition: opacity 1.5s linear;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: #1b2e3e;
  z-index: 200;
  display: flex;
}

#logoStartup {
  width: 70%;
  margin: auto;
}

/*### BLOCKLY CSS START*/

.blocklySelected > .blocklyPath {
  stroke: #d5d5d2 !important;
  stroke-width: 1.5px !important;
}

.blocklytext {
  font-family: DINCondensed-Regular !important;
  fill: black !important;
  font-style: normal;
}

.blocklyEditableText > .blocklytext {
  font-size: 17px !important;
}

/* hack for safari only*/

_::-webkit-full-page-media,
_:future,
:root .blocklytext {
  font-family: "DIN Condensed" !important;
}

.blocklySvg {
  background-color: #343434 !important;
}

.blocklyToolboxDiv {
  background-color: #1b2e3e !important;
  width: 76px;
  padding: 0px 0px 0px 4px;
  box-shadow: 1px 2px 5px #2a2a2a !important;
  z-index: 160;
  position: absolute !important;
  overflow: hidden !important;
}

.blocklyTreeRoot {
  padding-top: 32px !important;
  text-align: right;
}

.blocklyTreeRow {
  color: white;
}

.blocklytreerow {
  /* height: 33px !important; */
  /* line-height: 33px !important; */
  margin-bottom: 6px;
}

.blocklytreelabel {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  font-family: "Avenir Next LT W04 Condensed" !important;
  font-size: 20px !important;
  line-height: 20px !important;
  cursor: pointer !important;
}

.blocklyLevelSeparator {
  font-family: "Avenir Next LT W04 Condensed" !important;
  font-size: 20px !important;
  fill: #e7e7e4;
  line-height: 20px;
  text-align: left;
}

.blocklyButton {
  font-family: "Avenir Next LT W04 Condensed" !important;
  font-size: 20px !important;
  fill: #e7e7e4;
  line-height: 20px;
  text-align: left;
  cursor: pointer !important;
}

.blocklyButton:hover {
  fill: #00c90a !important;
}

.blocklyTreeSection {
  box-sizing: border-box;
  padding-right: 4px;
  color: #5393ca !important;
  display: block;
  width: 100%;
  margin: 22px auto 10px auto;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid #5393ca;
  border-left: 0 !important;
}

/* .blocklyTreeRow:not(.blocklyTreeSelected):hover {
     color: #6BD425!important;
     background-color: inherit!important;
   } */

.blocklyMainBackground {
  stroke: none !important;
}

.blocklyFlyoutBackground {
  fill: black !important;
}

.blocklyIconShape {
  fill: #000000 !important;
  stroke: none !important;
}

.blocklyIconShapeOutline {
  stroke: #000000;
  fill: none;
}

.blocklyicongroup:not(:hover) {
  opacity: 1 !important;
}

.blocklyIconOutline {
  opacity: 0;
}

/*### BLOCKLY CSS END*/

/** ### INPUT RANGE STYLE START ###**/

input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  padding: 0;
  /*fix for FF unable to apply focus style bug */
  /*border: 1px solid #171717;*/
  /*required for proper track sizing in FF*/
  width: 135px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 135px;
  height: 2px;
  background: #e7e7e4;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #e7e7e4;
  margin-top: -12px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e7e7e4;
}

input[type="range"]::-moz-range-track {
  width: 104px;
  height: 2px;
  background: #e7e7e4;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #e7e7e4;
}

/*hide the outline behind the border*/

input[type="range"]:-moz-focusring {
  outline: 1px solid #e7e7e4;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 135px;
  height: 2px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 14px 0;
  /*remove default tick marks*/
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #e7e7e4;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #e7e7e4;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #e7e7e4;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

/** ### INPUT RANGE STYLE STOP ###**/

#blockly\:2 {
  border-right: 4px solid #0bc90c;
}

#blockly\:3 {
  border-right: 4px solid #ff7401;
}

#blockly\:4 {
  border-right: 4px solid #fda908;
}

#blockly\:5 {
  border-right: 4px solid #cfee15;
}

#blockly\:7 {
  border-right: 4px solid #3eb3fe;
}

#blockly\:8 {
  border-right: 4px solid #3e75fe;
}

#blockly\:a {
  border-right: 4px solid #b3b3b0;
}

#blockly\:b {
  border-right: 4px solid #fddb07;
}

#blockly\:d {
  border-right: 4px solid #ab7ffc;
}

#blockly\:f {
  border-right: 4px solid #fd3f3f;
}

#blockly\:2 > .blocklyTreeSelected {
  background: #044904 !important;
}

#blockly\:3 > .blocklyTreeSelected {
  background: #522500 !important;
}

#blockly\:4 > .blocklyTreeSelected {
  background: #482f01 !important;
}

#blockly\:5 > .blocklyTreeSelected {
  background: #263b00 !important;
}

#blockly\:7 > .blocklyTreeSelected {
  background: #084961 !important;
}

#blockly\:8 > .blocklyTreeSelected {
  background: #001957 !important;
}

#blockly\:a > .blocklyTreeSelected {
  background: #41413f !important;
}

#blockly\:b > .blocklyTreeSelected {
  background: #443b01 !important;
}

#blockly\:d > .blocklyTreeSelected {
  background: #422351 !important;
}

#blockly\:f > .blocklyTreeSelected {
  background: #550101 !important;
}

.blocklyTreeRow:hover,
.blocklyTreeSelected {
  color: white !important;
}

.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background: transparent;
}

#blockly\:2 > .blocklyTreeRow:hover {
  background: #044904;
}
#blockly\:3 > .blocklyTreeRow:hover {
  background: #522500;
}
#blockly\:4 > .blocklyTreeRow:hover {
  background: #482f01;
}
#blockly\:5 > .blocklyTreeRow:hover {
  background: #263b00;
}
#blockly\:7 > .blocklyTreeRow:hover {
  background: #084961;
}
#blockly\:8 > .blocklyTreeRow:hover {
  background: #001957;
}
#blockly\:a > .blocklyTreeRow:hover {
  background: #41413f;
}
#blockly\:b > .blocklyTreeRow:hover {
  background: #443b01;
}
#blockly\:d > .blocklyTreeRow:hover {
  background: #422351;
}
#blockly\:f > .blocklyTreeRow:hover {
  background: #550101;
}

@media only screen and (max-width: 991px) {
  .navSection {
    margin: 6px 20px 0px 0px;
  }
  #modalCodeNavTriangleWrapper {
    left: 178px;
  }
  #modalConnectNavTriangleWrapper {
    left: 434px;
  }
  #modalSimulatorNavTriangleWrapper {
    left: 308px;
  }
}

.blocklyIconShape:hover {
  fill: #bdbdbb !important;
}

.blocklyIconGroup.active > .blocklyIconShape {
  fill: #bdbdbb !important;
  transition: fill 2s linear 1s;
}

/** Line numbers styles */

.hljs {
  margin: 0;
  padding: 10px;
  background-color: #f2f2f2 !important;
  color: #0086b3 !important;
  line-height: 21px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.hljs-ln {
  padding: 8px 0;
}

.hljs-ln tr:first-child td {
  padding-top: 8px !important;
}

.hljs-ln tr:last-child td {
  padding-bottom: 8px !important;
}

.hljs-meta {
  font-weight: normal !important;
}

/* for block of numbers */

td.hljs-ln-numbers {
  user-select: none;
  text-align: center;
  border-right: 1px solid gray;
  vertical-align: top;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

/* for block of code */

td.hljs-ln-code {
  padding-left: 8px !important;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #006c62 !important;
}

.hljs-keyword {
  color: #333;
}

td {
  white-space: pre !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.hideme {
  position: absolute;
  left: -9999px;
}

.settings {
  background: url("./assets/image/settings_icon.png");
  width: 18px;
  height: 18px;
  background-position: center;
  background-size: cover;
}

.settings:hover {
  background: url("./assets/image/settings_icon_hover.png");
  width: 18px;
  height: 18px;
  background-position: center;
  background-size: cover;
}

.setting-input {
  margin: 10px 0px;
}


.input1[type="checkbox"]:checked:after {

  color: #fff;
  display: flex;
  content: "";
  position: relative;
  top: 3.5px;
  left: 3.5px;  
  width: 10px;
  height: 10px; 
  background: white;
}

input[type="checkbox"]:checked {

  background-color: #fff;
 
}

input[type="checkbox"] {
  text-align: center;
  background-color: #171717;
  vertical-align: middle;
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 10%;
  border: 1px solid #fff;
  box-shadow: none;
  font-size: 1em;
  margin-right: 5px;
}

@media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #editorModalDivSpeedWrapper {
    right: 2vw;
  }
}
